* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

html,
body {
  overflow: hidden;
  height: 100%;
}

html {
  font-size: 16px;
}

@media (min-width: 480px) {
  html {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 32px;
  }
}

body {
  touch-action: none;
  background: radial-gradient(88.75% 50% at 50% 50%, #2a308b 0%, #1c1530 100%);
}

body,
button {
  font-family: 'Texturina', serif;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
